import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import { useTranslation } from "react-i18next"
import { useLocation } from "@reach/router"
import jwt_decode from "jwt-decode"
import { theme } from "../theme"
import { BrinkContext } from "../components/context/BrinkContext"
import { PageProvider } from "../components/context/PageContext"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM } from "../constants"
import Head from "../components/Head"
import Header from "../components/checkout/Header"
import Loader from "../components/ui/Loader"
import Item from "../components/cart/Item"
import Totals from "../components/cart/Totals"
import Box from "../components/checkout/Box"
import Shipping from "../components/checkout/Shipping"
import Discount from "../components/checkout/Discount"
import Payment from "../components/checkout/Payment"
import Upsell from "../components/checkout/Upsell"
import * as events from "../components/context/utils/events"
import { graphql } from "gatsby"

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${(p) => p.theme.colors.backgroundDark};
`

const Body = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${containerSmallMaxWidth};
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 3rem 2rem;

  h1 {
    width: 100%;
    margin: 1rem 3rem 4rem;

    ${MEDIA_MIN_MEDIUM} {
      margin: 6rem 3rem 6rem;
    }
  }
`

const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: auto;

  ${MEDIA_MIN_MEDIUM} {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`

const GridItem = styled.div`
  width: 100%;

  ${MEDIA_MIN_MEDIUM} {
    width: calc(50% - 2rem);
  }
`

const Left = styled(GridItem)`
  order: 2;

  ${MEDIA_MIN_MEDIUM} {
    order: 1;
  }
`

const Right = styled(GridItem)`
  order: 1;

  ${MEDIA_MIN_MEDIUM} {
    order: 2;
  }
`

const ShippingBox = styled(Box)`
  margin: 0 0 2rem;
  padding-bottom: 0rem;
`

const Cart = styled(Box)`
  margin-bottom: 2rem;
  padding: 2rem 2rem 1rem;

  h3 {
    width: 100%;

    span {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: block;
      }

      ${MEDIA_MIN_MEDIUM} {
        &:first-child {
          display: block;
        }

        &:last-child {
          display: none;
        }
      }
    }
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 3rem 2rem;
  }
`

const Items = styled.div`
  width: 100%;
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
`

const PaymentFailure = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  width: 100%;
  margin-bottom: 3rem;
  background: #f9c7c5;
  color: #9e362c;

  i {
    font-size: 3rem;
    margin-right: 1rem;
  }
`

const Sticky = styled.div`
  ${MEDIA_MIN_MEDIUM} {
    position: sticky;
    top: ${(p) => (p.hasDiscountBanner ? "9rem" : "3rem")};
  }
`

const CheckoutPage = ({
  data: { sanityConfigUpsell, allSanityShippingOption },
  pageContext
}) => {
  const { t } = useTranslation("translations")
  const location = useLocation()
  const { sanityDiscountBanner } = pageContext

  const {
    cart,
    isLoading,
    currentStore,
    getSetCart,
    brinkSessionId,
    listName
  } = useContext(BrinkContext)

  const getCart = async (token) =>
    await getSetCart(jwt_decode(token).sessionId, token)

  const isEmptyCart = (cart) =>
    cart.cartItems.filter((p) => p.type === "productVariant").length === 0

  const cartQuantity = cart.cartItems.reduce((prev, cur) => {
    if (cur.type === "shippingOption") return prev
    return prev + cur.quantity
  }, 0)

  const useMountEffect = (fun) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fun, [])

  useMountEffect(() => {
    const startCheckout = async () => {
      const params = new URLSearchParams(location.search)
      let storedCart = cart
      const token = params.get("cart")
      if (token) {
        storedCart = await getCart(token)
      }

      if (!isEmptyCart(storedCart)) {
        events.initiateCheckout(
          storedCart,
          currentStore,
          brinkSessionId,
          listName
        )
      }
    }
    startCheckout()
  })

  useEffect(() => {
    if (isEmptyCart(cart)) {
      navigate("/")
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  const cartHasShippingOption = cart.cartItems.find(
    (p) => p.type === "shippingOption"
  )

  return (
    <ThemeProvider theme={theme(pageContext.sanityTheme)}>
      <PageProvider pageContext={pageContext}>
        {cart && cart.cartItems.length > 0 && (
          <Wrapper className="top">
            <Helmet>
              <html lang="en" className="noOverflow" />
              <body className="noOverflow" />
            </Helmet>
            <Head meta={{ title: "Checkout" }} />
            <Header />
            <Body>
              <Loader isLoading={isLoading} />
              {location.state && location.state.error === "payment-failure" && (
                <PaymentFailure>
                  <i className="fal fa-exclamation-circle"></i>
                  {t(
                    "Something went wrong with your payment. Please try another option or contact our support."
                  )}
                </PaymentFailure>
              )}
              <Grid>
                <Left>
                  <ShippingBox>
                    <h3>{t("Shipping")}</h3>
                    <Shipping sanityShippingOptions={allSanityShippingOption} />
                  </ShippingBox>
                  <Box>
                    <h3>{t("Discount code / gift card")}</h3>
                    <Discount />
                  </Box>
                  {cartHasShippingOption && <Payment />}
                </Left>
                <Right>
                  <Sticky
                    hasDiscountBanner={
                      sanityDiscountBanner?.enabled &&
                      sanityDiscountBanner?.sticky
                    }
                  >
                    <Cart>
                      <h3>
                        <span>{t("Cart")}</span>
                        <span>
                          {cartQuantity}{" "}
                          {cartQuantity === 1 ? t("product") : t("produkter")}
                        </span>
                      </h3>
                      <Items>
                        {cart.cartItems.map((item) => (
                          <Item item={item} key={item.id} showControls />
                        ))}
                      </Items>
                      <Totals />
                    </Cart>
                    {sanityConfigUpsell?.upsellEnabled && (
                      <Upsell upsellData={sanityConfigUpsell} />
                    )}
                  </Sticky>
                </Right>
              </Grid>
            </Body>
          </Wrapper>
        )}
      </PageProvider>
    </ThemeProvider>
  )
}

export default CheckoutPage

export const query = graphql`
  query {
    allSanityShippingOption {
      nodes {
        id: _id
        displayName {
          en
          de
        }
        description {
          en
          de
        }
        mainImage {
          asset {
            gatsbyImageData(width: 150)
          }
        }
      }
    }
    sanityConfigUpsell {
      upsellEnabled
      upsellTitle
      upsellProducts {
        variants {
          _id
          mainImage {
            asset {
              gatsbyImageData(width: 150)
            }
          }
          displayName {
            en
            de
          }
          children {
            id
            ... on BrinkProductVariant {
              price {
                amount
                currencyUnit
              }
            }
          }
        }
      }
    }
  }
`
