import React, { useContext, useState } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../context/BrinkContext"
import Dinero from "dinero.js"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import Button from "../ui/Button"
import Box from "./Box"

const Container = styled.div`
  width: 100%;
  padding-top: 0;
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
`

const Close = styled.div`
  position: absolute;
  top: 2.8rem;
  font-size: 2rem;
  right: 3rem;
  cursor: pointer;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0 2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};

  &:last-of-type {
    border: none;
    padding: 2rem 0 0;
  }
`

const Image = styled(GatsbyImage)`
  width: 8rem;
  margin-right: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    width: 8rem;
  }
`

const Details = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;

  span {
    width: 100%;
    margin: 0.3rem 0;
    text-transform: uppercase;

    ${MEDIA_MIN_MEDIUM} {
      margin: 0.5rem 0;
    }
  }
`

const Name = styled.span`
  font-size: 1.3rem;
  font-weight: 700;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.5rem;
  }
`

const Price = styled.span`
  font-size: 1.4rem;
`

const AddToCartButton = styled(Button)`
  display: inline-flex;
  width: auto;
  background: ${(p) => p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.black};
  height: 4rem;
  width: 4rem;
  border-radius: 2rem;
  padding: 0 1.2rem 0.1rem;
  margin-left: 2rem;
  transition: opacity 0.2s;

  &:hover {
    background: ${(p) => p.theme.colors.secondaryOpac};
    opacity: 0.8;
  }

  i {
    font-size: 1rem;
    margin: 0.2rem 0 0 0.1rem;
  }

  span {
    display: none;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 0 2.5rem 0.1rem;
    width: auto;
    border-radius: 0;

    i {
      display: none;
    }
    span {
      display: inline-block;
    }
  }
`

const Upsell = ({ upsellData }) => {
  const [showUpsell, setShowUpsell] = useState(true)
  const { addProductVariantsToCart, languageCode, currentStore, setIsLoading } =
    useContext(BrinkContext)
  const { t } = useTranslation("translations")

  const findPrice = (price) => {
    const currencyPrice = price.find(
      (p) => p.currencyUnit === currentStore.currencyUnit
    )
    return currencyPrice ? currencyPrice.amount : undefined
  }

  if (!showUpsell) return false

  return (
    <Box>
      <h3>{t("Add something extra?")}</h3>

      <Container>
        <Close onClick={() => setShowUpsell(false)}>
          <i className="fal fa-times"></i>
        </Close>
        {upsellData.upsellProducts.map((product) => {
          const { variants } = product
          if (!variants.length) return false
          const { _id, mainImage, displayName, children } = variants[0]
          const { price } = children[0] || {}

          if (!price) return null

          return (
            <Item key={displayName.en}>
              <Image
                fluid={mainImage.asset.fluid}
                alt={displayName[languageCode] || displayName.en}
              />
              <Details>
                <Name>{displayName[languageCode] || displayName.en}</Name>
                <Price>
                  {toDinero(
                    findPrice(price) || 0,
                    currentStore.currencyUnit
                  ).toFormat()}
                </Price>
              </Details>
              <AddToCartButton
                onClick={() => {
                  addProductVariantsToCart(_id, 1)
                  setIsLoading(true)
                }}
              >
                <i className="fal fa-plus"></i>
                <span>{t("Add")}</span>
              </AddToCartButton>
            </Item>
          )
        })}
      </Container>
    </Box>
  )
}

const toDinero = (amount, currencyUnit) => {
  return new Dinero({ amount: amount, currency: currencyUnit })
}

export default Upsell
