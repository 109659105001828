import React, { useContext, useState, useEffect, useRef } from "react"
import styled, { withTheme } from "styled-components"
import { BrinkContext } from "../context/BrinkContext"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import { Loader } from "../ui/Loader"

const ErrorMessage = styled.div`
  color: ${(p) => p.theme.colors.errors};
  border: 0.1rem solid ${(p) => p.theme.colors.errors};
  padding: 1.5rem 2rem;
  letter-spacing: 0;
  margin-bottom: 2rem;
  width: 100%;
  line-height: 1.8rem;
`

const Checkout = styled.div`
  width: 100%;
  padding: 3rem 0.5rem 1rem;

  ${MEDIA_MIN_MEDIUM} {
    padding: 3rem 1.5rem 1rem;
  }
`

const CheckoutLoader = styled(Loader)`
  margin: 2em auto;
`

const KlarnaCheckout = ({ snippet }) => {
  const useMountEffect = (fun) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fun, [])
  const kcoRef = useRef(null)

  useMountEffect(() => {
    const scriptsTags = kcoRef.current.getElementsByTagName("script")
    // This is necessary otherwise the scripts tags are not going to be evaluated
    for (let i = 0; i < scriptsTags.length; i++) {
      const parentNode = scriptsTags[i].parentNode
      const newScriptTag = document.createElement("script")
      newScriptTag.type = "text/javascript"
      newScriptTag.text = scriptsTags[i].text
      parentNode.removeChild(scriptsTags[i])
      parentNode.appendChild(newScriptTag)
    }

    window._klarnaCheckout((api) => {
      api.on({
        load() {
          console.log("_klarnaCheckout:load")
          typeof window.gtag !== "undefined" &&
            window.gtag("event", "checkout_progress", {
              checkout_step: 4,
              checkout_option: "Started Payment"
            })
        },
        redirect_initiated() {
          console.log("_klarnaCheckout:redirect_initiated")
          typeof window.gtag !== "undefined" &&
            window.gtag("event", "checkout_progress", {
              checkout_step: 5,
              checkout_option: "Payment Submitted"
            })
        }
      })
    })
  })

  return <Checkout ref={kcoRef} dangerouslySetInnerHTML={{ __html: snippet }} />
}

export default withTheme(({ theme }) => {
  const { t } = useTranslation("translations")
  const { cartToKlarnaOrder, cart } = useContext(BrinkContext)
  const [displayError, setDisplayError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [klarnaSnippet, setKlarnaSnippet] = useState(null)

  useEffect(() => {
    const createKlarnaCheckout = async () => {
      setIsLoading(true)
      const klarnaOrder = await cartToKlarnaOrder({
        additional_checkbox: {
          text: t("Subscribe to newsletter"),
          checked: false,
          required: false
        }
      })
      setIsLoading(false)
      !klarnaSnippet && klarnaOrder && setKlarnaSnippet(klarnaOrder.html_snippet)
    }

    window._klarnaCheckout && window._klarnaCheckout((api) => api.suspend())

    createKlarnaCheckout()
      .catch(() => setDisplayError(true))
      .finally(() => {
        window._klarnaCheckout && window._klarnaCheckout((api) => api.resume())
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  return (
    <>
      {isLoading && <CheckoutLoader color={theme.colors.black} />}
      {displayError && (
        <ErrorMessage>
          {t(
            "Something went wrong with your payment. Please try another option or contact our support."
          )}
        </ErrorMessage>
      )}
      {klarnaSnippet && <KlarnaCheckout snippet={klarnaSnippet} />}
    </>
  )
})
