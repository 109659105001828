import React, { useState, useContext } from "react"
import { BrinkContext } from "../context/BrinkContext"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Box from "./Box"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import PaymentAdyen from "./PaymentAdyen"
import PaymentKlarna from "./PaymentKlarna"

const PaymentMethods = styled(Box)`
  min-height: 35rem;
  padding: 0;
  margin-top: -0.1rem;

  h3 {
    margin: 3rem 2rem 1rem;

    ${MEDIA_MIN_MEDIUM} {
      margin: 3rem 3rem 0;
    }
  }

  > p {
    padding: 0 2rem;
    text-align: left;
    line-height: 1.8rem;
  }
`

const Tabs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
`

const Tab = styled.button`
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  background: ${(p) =>
    p.active ? p.theme.colors.white : p.theme.colors.white};
  cursor: pointer;
  padding-bottom: 0.2rem;
  font-size: 1.3rem;
  line-height: 1.4rem;
  font-weight: 700;
  transition: all 0.2s;
  z-index: ${(p) => (p.active ? 1 : 0)};
  border: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  border-top: 0.4rem solid
    ${(p) => (p.active ? p.theme.colors.secondary : p.theme.colors.lightBorder)};
  border-bottom: 0.1rem solid
    ${(p) => (p.active ? "transparent" : p.theme.colors.lightBorder)};

  &:hover {
    border-top: 0.4rem solid
      ${(p) => (!p.active ? p.theme.colors.lightGrey : "none")};
  }

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.4rem;
  }
`

const Payment = ({ setShowSummary }) => {
  const { t } = useTranslation("translations")
  const { ENABLE_KLARNA_CHECKOUT } = useContext(BrinkContext)
  const isKlarnaEnabled = ENABLE_KLARNA_CHECKOUT
  const [paymentProvider, setPaymentProvider] = useState(
    isKlarnaEnabled ? "Klarna" : "Adyen"
  )

  return (
    <>
      {isKlarnaEnabled && (
        <Tabs>
          <Tab
            active={paymentProvider === "Klarna"}
            onClick={() => setPaymentProvider("Klarna")}
          >
            {t("Klarna")}
          </Tab>
          <Tab
            active={paymentProvider === "Adyen"}
            onClick={() => setPaymentProvider("Adyen")}
          >
            {t("Other options")}
          </Tab>
        </Tabs>
      )}
      <PaymentMethods>
        {!isKlarnaEnabled && <h3>{t("Payment")}</h3>}
        {paymentProvider === "Klarna" && <PaymentKlarna />}
        {paymentProvider === "Adyen" && (
          <PaymentAdyen setShowSummary={setShowSummary} />
        )}
      </PaymentMethods>
    </>
  )
}

export default Payment
