import React, { useState } from "react"
import styled from "styled-components"
import { ErrorMessage } from "react-hook-form"

const Container = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 3.2rem;
`

const Field = styled.input`
  border: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
  width: 100%;
  text-transform: initial;
  padding: 1.2rem 2rem 0;
  height: 5.5rem;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(p) => p.theme.colors.black};
  }
`

const Label = styled.label`
  transition: all 0.2s;
  position: absolute;
  left: 2rem;
  color: #666;
  margin: 0;
  letter-spacing: 0.05rem;
  display: block;
  font-size: ${(p) => (p.active ? "1.1rem" : "1.3rem")};
  top: ${(p) => (p.active ? "1rem" : "1.8rem")};
`

const Validation = styled.div`
  color: ${(p) => p.theme.colors.errors};
  display: block;
  position: absolute;
  border-top: 0.2rem solid ${(p) => p.theme.colors.errors};
  width: 100%;
  padding-top: 0.4rem;
  letter-spacing: 0;
  font-size: 1.2rem;
  margin-top: -0.1rem;
`

const Input = ({
  id,
  placeholder,
  label,
  type,
  name,
  contextValue,
  errors,
  ...other
}) => {
  const [isFocused, setIsFocused] = useState(null)

  return (
    <Container {...other}>
      {label && (
        <Label active={contextValue || isFocused} htmlFor={name}>
          {label}
        </Label>
      )}
      <Field
        id={id || name}
        name={name}
        defaultValue={contextValue}
        type={type || "text"}
        placeholder={placeholder}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={(e) => {
          if (!e.target.value.length) setIsFocused(false)
        }}
      />
      {errors && (
        <ErrorMessage errors={errors} name={name}>
          {({ message }) => <Validation>{message}</Validation>}
        </ErrorMessage>
      )}
    </Container>
  )
}

export default Input
