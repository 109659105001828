import React, { useContext, useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import Box from "./Box"
import Address from "../checkout/Address"
import Button from "../ui/Button"
import PaymentLogos from "./PaymentLogos"
import AdyenDropin from "./AdyenDropin"
import Summary from "./Summary"
import * as events from "../context/utils/events"
import { parsePhoneNumberFromString } from "libphonenumber-js/min"

const AddressBox = styled(Box)`
  margin: 0 0 2rem;
  padding: 3rem 2rem 0;
  border: none;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0 0 2rem;
    padding: 3rem 3rem 0;
  }
`

const SummaryBox = styled(Box)`
  margin: 0 0 2rem;
  padding-bottom: 1rem;
  border: none;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0 0 2rem;
  }

  h3 {
    margin-left: 0;
  }
`

const ContinueButton = styled(Button)`
  margin: 1rem 0;
width: 100%;
`

const PaymentAdyen = () => {
  const { t } = useTranslation("translations")
  const { setIsLoading, shippingAddress, setShippingAddress, currentStore } =
    useContext(BrinkContext)
  const [showSummary, setShowSummary] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const { control, handleSubmit, errors, setValue } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur"
  })

  const formatPhoneNumber = (data) => {
    const phoneNumber = parsePhoneNumberFromString(
      data || "",
      currentStore.countryCode
    )
    return phoneNumber ? phoneNumber.number : ""
  }

  const onContinueToPayment = (data) => {
    typeof window.gtag !== "undefined" &&
      window.gtag("event", "checkout_progress", {
        checkout_step: 3,
        checkout_option: "Address Form Submitted"
      })
    console.log("Form data:", data)
    setIsLoading(true)

    const updatedShippingAddress = {
      ...shippingAddress,
      ...data,
      phone: formatPhoneNumber(data.phone)
    }
    setShippingAddress(updatedShippingAddress)
    events.identify(updatedShippingAddress)
    setShowSummary(true)
    setShowPayment(true)
  }

  return (
    <>
      {!showPayment && !showSummary && (
        <form onSubmit={handleSubmit(onContinueToPayment)}>
          <AddressBox>
            <Address
              control={control}
              errors={errors}
              showPayment={showPayment}
              setValue={setValue}
            />
            <ContinueButton type="submit">{t("Continue")}</ContinueButton>
            <PaymentLogos />
          </AddressBox>
        </form>
      )}
      {showSummary && (
        <SummaryBox>
          <Summary
            setShowSummary={setShowSummary}
            setShowPayment={setShowPayment}
          />
        </SummaryBox>
      )}
      {showPayment && <AdyenDropin />}
    </>
  )
}

export default PaymentAdyen
