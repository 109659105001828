import React, { useEffect, useState, useContext, useRef } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { useTranslation, Trans } from "react-i18next"
import { useForm, Controller } from "react-hook-form"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import Link from "../Link"
import Checkbox from "../ui/Checkbox"
import Button from "../ui/Button"
import { subscribeToNewsletter } from "../context/utils/events/klaviyo"
import { subscribeToNewsletter as pinterestTagSubscribeToNewsletter } from "../context/utils/events/pinterestTag"
import "@adyen/adyen-web/dist/adyen.css"
import "../../styles/adyen.css"

const Label = styled.label`
  margin-left: 1.5rem;
  margin-top: 0;
  text-transform: initial;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0;
  white-space: pre-wrap;
  cursor: pointer;
`

const Checkout = styled.div`
  padding: 2rem 2rem 0;
  margin: 0 0 2rem;
  width: 100%;
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};

  ${MEDIA_MIN_MEDIUM} {
    margin: 0;
    padding: 0 3rem 3rem;
  }

  p {
    color: ${(p) => p.theme.colors.darkGrey};
    margin: 2rem 0 0;
    letter-spacing: 0;
  }

  form > div {
    padding: 2rem 2rem 0 0;

    ${MEDIA_MIN_MEDIUM} {
      padding: 3rem 0 0;
    }
  }
`

const ContinueButton = styled(Button)`
  margin-top: 1rem;
  width: 100%;
`

const ErrorMessage = styled.div`
  color: ${(p) => p.theme.colors.errors};
  border: 0.1rem solid ${(p) => p.theme.colors.errors};
  padding: 1.5rem 2rem;
  letter-spacing: 0;
  margin-bottom: 2rem;
  width: 100%;
  line-height: 1.8rem;
`

const RetryButton = styled(Button)`
  margin-top: 1rem;
`

const AdyenDropin = () => {
  const { t } = useTranslation("translations")
  const [displayError, setDisplayError] = useState(false)
  const [newsletterChecked, setNewsletterChecked] = useState(false)
  const [showCheckout, setShowCheckout] = useState(true)
  const [showPayPalButton, setShowPayPalButton] = useState(false)
  const [payPalActions, setPayPalActions] = useState(null)
  const [showRetryButton, setShowRetryButton] = useState(false)
  const dropin = useRef(null)
  const paypalComponent = useRef(null)

  const {
    control,
    handleSubmit,
    errors,
    triggerValidation,
    formState: { isValid }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur"
  })
  const {
    ADYEN_ENVIRONMENT,
    ADYEN_CLIENT_KEY,
    getPaymentMethods,
    cartToOrder,
    makePayment,
    cart,
    currentStore,
    languageCode,
    setOrder,
    closeCart,
    setIsLoading,
    makeDetailsCall,
    setIsCartOpen,
    shippingAddress,
    isCartClosed,
    discountCode
  } = useContext(BrinkContext)

  const handleResult = (response, order) => {
    if (
      response.resultCode === "Authorised" ||
      response.resultCode === "Received"
    ) {
      setOrder(order)
      closeCart()
      setIsCartOpen(false)
      if (newsletterChecked) {
        subscribeToNewsletter(currentStore.countryCode, shippingAddress.email)
        pinterestTagSubscribeToNewsletter()
      }
      navigate("/success/", {
        state: { order, cart, billingAddress: shippingAddress }
      })
    } else {
      console.log(response)
      setDisplayError(true)
      setShowRetryButton(true)
    }
  }

  useEffect(() => {
    if (!paypalComponent.current) return
    if (!showPayPalButton) return

    if (paypalComponent.current._node) {
      paypalComponent.current.remount()
    } else {
      paypalComponent.current.mount("#paypal-container")
    }
  }, [showPayPalButton])

  useEffect(() => {
    if (!payPalActions) return
    if (isValid) {
      payPalActions.enable()
    } else {
      payPalActions.disable()
    }
  }, [payPalActions, isValid])

  useEffect(() => {
    showRetryButton && dropin.current.unmount()
  }, [showRetryButton])

  useEffect(() => {
    typeof window.gtag !== "undefined" &&
      window.gtag("event", "checkout_progress", {
        checkout_step: 4,
        checkout_option: "Started Payment"
      })

    const generateAdyenPayment = async () => {
      const [paymentMethods, order] = await Promise.all([
        getPaymentMethods(),
        cartToOrder()
      ])
      const onSubmit = (state, adyen) => {
        typeof window.gtag !== "undefined" &&
          window.gtag("event", "checkout_progress", {
            checkout_step: 5,
            checkout_option: "Payment Submitted"
          })
        setDisplayError(false)
        setIsLoading(true)
        makePayment(
          order,
          state.data.paymentMethod,
          state.data.storePaymentMethod,
          state.data.browserInfo
        )
          .then((response) => {
            const { paymentResult, order } = response
            if (paymentResult.action) {
              setOrder(order)
              setIsLoading(false)
              setShowCheckout(false)
              adyen.handleAction(paymentResult.action)
            } else {
              handleResult(paymentResult, order)
              setIsLoading(false)
            }
          })
          .catch((error) => {
            console.error(error)
            setIsLoading(false)
            navigate("/error/")
          })
      }
      const onAdditionalDetails = (state, adyen) => {
        makeDetailsCall(order.id, state.data.details)
          .then((response) => {
            const { paymentResult, order } = response
            if (paymentResult.action) {
              setOrder(order)
              setIsLoading(false)
              adyen.handleAction(paymentResult.action)
            } else {
              handleResult(paymentResult, order)
              setIsLoading(false)
            }
          })
          .catch((error) => {
            console.error(error)
            setIsLoading(false)
            navigate("/error/")
          })
      }
      setIsLoading(false)
      const configuration = {
        amount: {
          value: cart.totalPriceWithDiscount,
          currency: currentStore.currencyUnit
        },
        paymentMethodsResponse: paymentMethods,
        clientKey: ADYEN_CLIENT_KEY,
        locale: languageCode,
        environment: ADYEN_ENVIRONMENT,
        showPayButton: false,
        onSubmit,
        onAdditionalDetails,
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            enableStoreDetails: true,
            hideCVC: false,
            name: t("Credit or debit card")
          }
        }
      }

      import("@adyen/adyen-web")
        .then(({ default: AdyenCheckout }) => {
          const checkout = new AdyenCheckout(configuration)
          if (dropin.current) {
            dropin.current.unmount()
          }
          dropin.current = checkout
            .create("dropin", {
              onSelect: ({ props: { type } }) =>
                setShowPayPalButton(type === "paypal")
            })
            .mount("#dropin-container")

          if (paymentMethods.paymentMethods.find((p) => p.type === "paypal")) {
            paypalComponent.current = checkout.create("paypal", {
              ...configuration,
              merchantId: "ZMVVQ388KSQHJ",
              countryCode: currentStore.countryCode,
              showPayButton: true,
              intent: "capture",
              onInit: (_, actions) => {
                actions.disable()
                setPayPalActions(actions)
              },
              onClick: () => triggerValidation(),
              onCancel: () => setShowCheckout(true)
            })
          }
        })
        .catch((error) => console.error(error))
    }

    !isCartClosed() && generateAdyenPayment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, discountCode])

  return (
    <>
      {displayError && (
        <ErrorMessage>
          {t(
            "Something went wrong with your payment. Please try another option or contact our support."
          )}
        </ErrorMessage>
      )}
      {showRetryButton && (
        <RetryButton
          onClick={() => {
            setDisplayError(false)
            setShowCheckout(true)
            setShowRetryButton(false)
            dropin.current.update()
          }}
        >
          {t("Retry")}
        </RetryButton>
      )}

      <div id="dropin-container" />

      <Checkout>
        <form onSubmit={handleSubmit(() => dropin.current.submit())}>
          {showCheckout && (
            <>
              <div>
                <Controller
                  as={Checkbox}
                  control={control}
                  type="checkbox"
                  name="newsletter"
                  id="newsletter"
                  value="newsletter"
                  defaultValue={false}
                  onChange={() => setNewsletterChecked(!newsletterChecked)}
                >
                  <Label htmlFor="newsletter">
                    {t("Subscribe to newsletter")}
                  </Label>
                </Controller>

                <Controller
                  as={Checkbox}
                  control={control}
                  rules={{
                    required: t(
                      "You need to accept the terms and agreements to be able to place an order"
                    )
                  }}
                  type="checkbox"
                  name="agreement"
                  id="agreement"
                  value="agreement"
                  errors={errors}
                  defaultValue={false}
                >
                  <Label htmlFor="agreement">
                    <Trans i18nKey="termsAgreement">
                      I agree to the{" "}
                      <Link underline color="black" to="/terms-and-conditions/">
                        terms and conditions
                      </Link>{" "}
                      of Rondure
                    </Trans>
                  </Label>
                </Controller>
              </div>
              {!showPayPalButton && (
                <ContinueButton type="submit">
                  {t("Complete order")}
                </ContinueButton>
              )}
            </>
          )}
          <div
            id="paypal-container"
            style={{ display: showPayPalButton ? "block" : "none" }}
          />
        </form>
      </Checkout>
    </>
  )
}

export default AdyenDropin
