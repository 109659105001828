import React from "react"
import styled from "styled-components"
import { Controller } from "react-hook-form"
import { getLocalizedCountryName } from "../../helpers/countries"

const SelectWrapper = styled.div`
  position: relative;

  &:after {
    content: "";
    display: inline-block;
    border: 0.5rem solid transparent;
    border-top: 0.6rem solid gray;
    border-bottom: 0 none;
    position: absolute;
    right: 2rem;
    top: 2.5rem;
  }
`

const CountrySelector = ({
  selectedCountry,
  setSelectedCountry,
  countryWhiteList,
  currentStore,
  control,
  languageCode,
  t
}) => (
  <SelectWrapper>
    <Controller
      as={
        <select
          value={selectedCountry}
          onBlur={(e) => setSelectedCountry(e.target.value)}
        >
          {countryWhiteList?.map((countryCode) => {
            return (
              <option value={countryCode} key={countryCode}>
                {getLocalizedCountryName(countryCode, languageCode)}
              </option>
            )
          })}
        </select>
      }
      name="country"
      control={control}
      rules={{
        required: t("This is a required field")
      }}
      defaultValue={currentStore.countryCode}
    />
  </SelectWrapper>
)

export default CountrySelector
