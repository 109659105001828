import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import { BrinkContext } from "../context/BrinkContext"
import { getLocalizedCountryName } from "../../helpers/countries"
import Link from "../Link"

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  padding: 1.7rem 0 1.5rem;

  &:last-child {
    border: none;
    padding-bottom: 0;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.8rem;
    padding-right: 8rem;
  }

  img {
    height: 2rem;
    margin-right: 1rem;
    max-width: 9rem;
    padding-top: 0.2rem;
  }
`

const ChangeLink = styled(Link)`
  color: ${(p) => p.theme.colors.black};
  height: 1.4rem;
  flex-shrink: 0;
  margin-left: 1rem;
  position: absolute;
  right: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    right: 3rem;
  }

  &:after {
    bottom: -0.8rem;
  }
`

const Label = styled.span`
  display: inline-block;
  width: 100%;
  margin-bottom: 0.5rem;
  font-weight: 700;
`

const Summary = ({ setShowPayment, setShowSummary }) => {
  const { t } = useTranslation("translations")
  const { shippingAddress, languageCode } = useContext(BrinkContext)
  const { colors } = useContext(ThemeContext)

  return (
    <>
      <Row>
        <div>
          <Label>{t("E-mail")}:</Label> {shippingAddress.email}
        </div>
        <ChangeLink
          to="/"
          underline
          color={colors.black}
          onClick={(e) => {
            e.preventDefault()
            setShowPayment(false)
            setShowSummary(false)
          }}
        >
          {t("Change")}
        </ChangeLink>
      </Row>
      <Row>
        <div>
          <Label>{t("Address")}:</Label> {shippingAddress.address},<br />
          {shippingAddress.postalCode} {shippingAddress.city},{" "}
          {getLocalizedCountryName(shippingAddress.country, languageCode)}
        </div>
        <ChangeLink
          to="/"
          underline
          color={colors.black}
          onClick={(e) => {
            e.preventDefault()
            setShowPayment(false)
            setShowSummary(false)
          }}
        >
          {t("Change")}
        </ChangeLink>
      </Row>
    </>
  )
}

export default Summary
