import React, { useState, useContext, useEffect, useRef } from "react"
import styled, { withTheme } from "styled-components"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import Radio from "../ui/Radio"
import { Loader } from "../ui/Loader"
import { BrinkContext } from "../context/BrinkContext"
import Dinero from "dinero.js"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};

  form {
    display: flex;
  }
`

const ShippingOption = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  padding: 1rem 0 2rem;
  flex-wrap: wrap;
  cursor: pointer;

  ${MEDIA_MIN_MEDIUM} {
  }

  &:last-of-type {
    border-bottom: none;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > div {
    align-items: center;
    flex: 1;
    display: flex;
  }
`

const Logo = styled(GatsbyImage)`
  max-width: 6rem;
  max-height: 2.5rem;
  margin: 0.2rem 2rem 0 1.5rem;
  display: none;

  ${MEDIA_MIN_MEDIUM} {
    display: block;
  }
`

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 5rem;
`

const Price = styled.span`
  font-size: 1.4rem;
`

const Name = styled.span`
  text-transform: uppercase;
  width: 100%;
  font-size: 1.4rem;

  span {
    font-weight: 700;
  }
`

const Description = styled.span`
  color: ${(p) => p.theme.colors.darkGrey};
  width: 100%;
  margin-top: 0.5rem;
  font-size: 1.3rem;
`

const ShippingLoader = styled(Loader)`
  margin: 2em auto;
`

const Shipping = withTheme(({ sanityShippingOptions, theme }) => {
  const {
    languageCode,
    getShippingOptions,
    currentStore,
    setShippingMethod,
    shippingMethod,
    cart
  } = useContext(BrinkContext)
  const [shippingOptions, setShippingOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [cartPrice, setCartPrice] = useState(() => cart.totalPrice)

  const { currencyUnit } = currentStore
  const Amount = Currency(currencyUnit)

  const previousShippingOptions = useRef()
  useEffect(() => {
    const initializeShippingOptions = async () => {
      setIsLoading(true)
      const availableShippingOptions = await getShippingOptions(
        currentStore.countryCode
      )
      const availableShippingOptionIds = availableShippingOptions.map(
        (e) => e.id
      )
      const previousShippingOptionsIds = previousShippingOptions.current.map(
        (e) => e.id
      )
      if (
        !availableShippingOptionIds.reduce(
          (a, b) => a && previousShippingOptionsIds.includes(b),
          true
        )
      ) {
        const shippingOptions = sanityShippingOptions.nodes
          .map((shippingOption) =>
            toShippingOption(availableShippingOptions, shippingOption)
          )
          .filter((x) => x)
          .sort((a, b) => a.sortOrder - b.sortOrder)
        setShippingOptions(shippingOptions)
        setShippingMethod(shippingOptions[0])
      }
    }

    initializeShippingOptions().finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartPrice])

  useEffect(() => {
    previousShippingOptions.current = shippingOptions
  }, [shippingOptions])

  useEffect(() => {
    if (cart.state === 'CLOSED') return
    cartPrice !== cart.totalPrice && setCartPrice(cart.totalPrice)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  const toShippingOption = (shippingOptions, shippingOption) => {
    const availableShippingOption = shippingOptions.find(
      (o) => o.id === shippingOption.id
    )
    return availableShippingOption
      ? {
          ...shippingOption,
          price: availableShippingOption.price
        }
      : undefined
  }

  const handleShippingClick = (id) => {
    setShippingMethod(shippingOptions.find((o) => o.id === id))
  }

  const isPreSelected = (shippingOption) =>
    (shippingMethod && shippingOption.id === shippingMethod.id) ||
    shippingOption.sortOrder === "1"

  return (
    <Container>
      {isLoading && <ShippingLoader color="black" />}
      {shippingOptions.map((shippingOption) => {
        const { mainImage, displayName, description, price } = shippingOption
        const priceMoney = Amount(price[currentStore.currencyUnit])
        return (
          <ShippingOption
            key={shippingOption.id}
            onClick={() => handleShippingClick(shippingOption.id)}
            htmlFor={shippingOption.id}
          >
            <Header>
              <div>
                <Radio
                  id={shippingOption.id}
                  name="shipping"
                  preSelected={
                    isPreSelected(shippingOption) ? "true" : undefined
                  }
                />
                {mainImage && (
                  <Logo image={getImage(mainImage?.asset)} alt={``} />
                )}
                <Details>
                  <Name>
                    <span>{displayName[languageCode] || displayName.en}</span>
                  </Name>
                  <Description>
                    {description[languageCode] || description.en}
                  </Description>
                </Details>
              </div>
              <Price>
                {priceMoney
                  .setLocale(languageCode)
                  .toFormat(getFormat(priceMoney))}
              </Price>
            </Header>
          </ShippingOption>
        )
      })}
    </Container>
  )
})
const getFormat = (money) => (money.hasCents() ? "$0,0.00" : "$0,0")

const Currency = (currency) => (amount) => Dinero({ currency, amount })

export default Shipping
