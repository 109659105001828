import React, { useContext } from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import { MEDIA_SMALL, MEDIA_MIN_MEDIUM } from "../../constants"
import { BrinkContext } from "../context/BrinkContext"

const Container = styled.div`
  padding: 1rem 3rem;
  width: 100%;
  margin: 0 auto;
  background: ${(p) => p.backgroundColor || p.theme.colors.primary};
  color: ${(p) => p.textColor || p.theme.colors.white};
  z-index: 11;
  min-height: 6rem;
  top: 0;
  position: ${(p) => (p.sticky ? "fixed" : "relative")};
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${MEDIA_SMALL} {
    font-size: 1.2rem;
    padding: 1rem 4rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.3rem;
    padding: 1.2rem 4rem 1rem;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SignupBanner = styled.div`
  cursor: ${(p) => (p.clickEnabled ? "pointer" : "initial")};
  position: relative;

  i {
    display: ${(p) => (p.clickEnabled ? "inline-block" : "none")};
    right: -3.5rem;
    top: 50%;
    margin-top: -1rem;
    position: absolute;
    font-size: 2rem;
    transition: all 0.2s;
  }

  ${MEDIA_MIN_MEDIUM} {
    margin-left: 3rem;
    padding-top: 0;

    &:hover {
      i {
        right: -3.8rem;
      }
    }
  }
`

const Title = styled.span`
  letter-spacing: 0;
  text-transform: uppercase;
  margin: 0.2rem auto;
  font-weight: 700;
  text-align: center;
  display: block;

  ${MEDIA_MIN_MEDIUM} {
    letter-spacing: 0.1rem;
  }
`

const SubTitle = styled.span`
  text-align: center;
  margin: 0 auto;
  display: block;
`

const DiscountBanner = ({ props }) => {
  const { languageCode, setShowNewsletterPopup, discountCodePopup } =
    useContext(BrinkContext)

  return (
    <StaticQuery
      query={graphql`
        query {
          sanityConfigDiscountBanner {
            enabled
            sticky
            enablePopup
            title {
              en
              de
            }
            subTitle {
              en
              de
            }
            useDiscountText {
              en
              de
            }
            backgroundColor
            textColor
          }
        }
      `}
      render={({ sanityConfigDiscountBanner }) => {
        const {
          enabled,
          sticky,
          enablePopup,
          title,
          subTitle,
          useDiscountText,
          backgroundColor,
          textColor
        } = sanityConfigDiscountBanner || {}

        if (!enabled) return null

        return (
          <Container
            backgroundColor={backgroundColor}
            textColor={textColor}
            sticky={sticky}
            {...props}
          >
            <Content>
              {!enablePopup ? (
                <div>
                  <Title>{title[languageCode] || title.en}</Title>
                  <SubTitle>{subTitle[languageCode] || subTitle.en}</SubTitle>
                </div>
              ) : (
                <>
                  {!discountCodePopup ? (
                    <SignupBanner
                      clickEnabled={enablePopup}
                      onClick={() =>
                        enablePopup ? setShowNewsletterPopup(true) : null
                      }
                    >
                      <div>
                        <Title>{title[languageCode] || title.en}</Title>
                        <SubTitle>
                          {subTitle[languageCode] || subTitle.en}
                        </SubTitle>
                      </div>
                      <i className="fal fa-chevron-right"></i>
                    </SignupBanner>
                  ) : (
                    <div>
                      <Title>' {discountCodePopup} '</Title>
                      <SubTitle>
                        {useDiscountText[languageCode] || useDiscountText.en}
                      </SubTitle>
                    </div>
                  )}
                </>
              )}
            </Content>
          </Container>
        )
      }}
    />
  )
}

export default DiscountBanner
