import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  height: 5rem;
  align-items: center;
`

const Input = styled.input.attrs((p) => ({
  type: "radio",
  name: p.name,
  id: p.id,
  value: p.value
}))`
  height: 2.4rem;
  width: 2.4rem;
  cursor: pointer;
  padding: 0;
  border: 0.1rem solid ${(p) => p.theme.colors.mediumGrey};
  border-radius: 50%;
  position: relative;
  transition: border 0.2s;

  &:hover,
  &:checked {
    border: 0.1rem solid ${(p) => p.theme.colors.black};
  }

  &:checked::after {
    content: "";
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background: ${(p) => p.theme.colors.black};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    outline: none;
  }
`

const Radio = ({ children, id, name, preSelected, ...other }) => (
  <Container {...other}>
    <Input id={id} name={name} defaultChecked={preSelected} />
    {children}
  </Container>
)

export default Radio
