import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useForm, Controller } from "react-hook-form"
import { BrinkContext } from "../context/BrinkContext"
import Input from "../ui/Input"
import Button from "../ui/Button"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 0 1rem;

  form {
    display: flex;
  }
`

const DiscountInput = styled(Input)`
  width: 60%;

  input {
    text-transform: initial;
    border: 0.1rem solid ${(p) => p.theme.colors.lightGrey};

    &::placeholder {
      color: ${(p) => p.theme.colors.darkGrey};
    }
  }
`

const DiscountButton = styled(Button)`
  width: calc(50% - 1rem);
  margin-left: 1rem;
`

const ActiveDiscounts = styled.div`
  width: 100%;

  p {
    text-transform: uppercase;
  }
`

const ActiveDiscount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(21, 21, 21, 0.03);
  width: 100%;
  padding: 1rem 2rem;

  button {
    background: none;
    border: none;
    padding: 0.4rem 0 0;
    cursor: pointer;
  }
`

const Discount = () => {
  const { t } = useTranslation("translations")
  const {
    discountCode,
    addDiscount,
    removeDiscountFromCart,
    setIsLoading,
    notification
  } = useContext(BrinkContext)

  const { control, handleSubmit, errors } = useForm({
    mode: "onBlur"
  })

  const addCode = (data, e) => {
    setIsLoading(true)
    addDiscount({ code: data.code })
    e.target.reset()
  }

  useEffect(() => {
    if (
      notification.CART.event === "APPLYING_DISCOUNT_CODE" &&
      notification.CART.severity === "ERROR"
    ) {
      errors["code"] = {
        message: t(`${notification.CART.message}`),
        ref: {
          name: "code"
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])

  return (
    <Container>
      {!discountCode && (
        <>
          <form onSubmit={handleSubmit(addCode)}>
            <Controller
              as={DiscountInput}
              control={control}
              rules={{
                required: t("Please provide a discount code"),
                pattern: {
                  value: /^[A-Za-z0-9_.]+$/,
                  message: t("Only characters Aa-Zz and 0-9 are allowed")
                }
              }}
              type="text"
              name="code"
              placeholder={t("Discount code")}
              defaultValue=""
              errors={errors}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSubmit(addCode)
              }}
            />
            <DiscountButton type="submit">{t("Add")}</DiscountButton>
          </form>
        </>
      )}
      {discountCode && (
        <ActiveDiscounts>
          <p>{t("Active discount codes:")}</p>
          <ActiveDiscount>
            {discountCode}
            <button
              onClick={() => {
                setIsLoading(true)
                removeDiscountFromCart()
              }}
            >
              <i className="fal fa-trash-alt"></i>
            </button>
          </ActiveDiscount>
        </ActiveDiscounts>
      )}
    </Container>
  )
}

export default Discount
